import React from 'react'
import _ from 'lodash'

import Menu from '../components/menu'
import BookTable from '../components/BookTable'
import Layout from '../components/layout'
import SEO from '../components/seo'
import useWindowSize from '../components/useWindowSize'

const IndexPage = ({data}) => {
    const books = _.groupBy(data.allMongodbIndexPrizeItems.edges.map(book => book.node), 'year')
    const {width} = useWindowSize()
    const sp = width < 640;

    return (
        <Layout>
            <SEO title='Home'/>
            <div className='ui relaxed centered grid'>
                <Menu active={'akutagawa'}/>
                <div className={`${sp ? 'sixteen' : 'eight'} wide column`}>
                    <BookTable books={books} prize_name='芥川賞'/>
                </div>
            </div>
        </Layout>
    )
}

export default IndexPage

export const query = graphql`
{
    allMongodbIndexPrizeItems (filter: {prize_name: {eq: "akutagawa"}}) {
        edges {
            node {
                author
                book
                year
                description
                image_path
                mongodb_id
                prize_name
            }
        }
    }
}
`
